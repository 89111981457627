.about {
  &--section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    box-shadow:rem(0 11px 35px cl(black, .16));
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    @include media-breakpoint-down(md) {
      padding-top: rem(100px);
    }
    @include media-breakpoint-down(xs) {
      padding-top: 4.25rem;
    }
    &:before {
      content: "";
      @extend .full;
      background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
  }
  &__side {
    position: relative;
    width:rem(670px);
    @include media-breakpoint-down(xs) {
        width:25rem;
        margin: 0 auto;
    }
    h2 {
      width:100%;
      font-size: rem(34px);
      line-height: 1;
      color:cl(primary);
      font-weight: normal;
      margin: rem(0 0 70px);
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-up(sm) {
        position: absolute;
        left:rem(-190px);
        top:rem(45px);
        transform: rotate(-45deg);
        margin: 0;
      }
      &:before,
      &:after {
        content: "";
        width:rem(108px);
        height: 1px;
        background: #32519e;
      }
      span {
        flex-shrink: 0;
        padding: rem(0 10px);

      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    position: relative;
    z-index: 2;
    li {
      &:nth-child(1),
      &:nth-child(4)  {
        width:100%;
      }
      &:nth-child(2),
      &:nth-child(3)  {
        width:50%;
      }
      a {
        position: relative;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width:rem(300px);
        height: rem(170px);
        transition: transform .3s ease;
        @include media-breakpoint-down(xs) {
          width: 10rem;
          height:7rem;
          padding: rem(0 5px);
        }
        &:hover {
          transform: scale(1.1);
        }
        &.shadow-red {
          &:before {box-shadow:rem(0 11px 35px cl(black, .16)), rem(-4px -4px 0 0 cl(red) inset), rem(1px 1px 0 0 cl(red) inset);}
        }
        &.shadow-yellow {
          &:before {box-shadow:rem(0 11px 35px cl(black, .16)), rem(-4px -4px 0 0 cl(yellow) inset), rem(1px 1px 0 0 cl(yellow) inset);}
        }
        &.shadow-blue {
          &:before {box-shadow:rem(0 11px 35px cl(black, .16)), rem(-4px -4px 0 0 cl(blue) inset), rem(1px 1px 0 0 cl(blue) inset);}
        }
        &.shadow-violet {
          &:before {box-shadow:rem(0 11px 35px cl(black, .16)), rem(-4px -4px 0 0 cl(violet) inset), rem(1px 1px 0 0 cl(violet) inset);}
        }
        &:before {
          content: "";
          position: absolute;
          top: rem(-26px);
          left: rem(38px);
          width:rem(224px);
          height:rem(224px);
          background: cl(white);
          border-radius: rem(20px);
          transform: rotate(45deg);
          @include media-breakpoint-down(xs)  {
            width: 9rem;
            height: 9rem;
            left:0.5rem;
            top:-1rem;
          }
        }
        img {
          position: relative;
          z-index: 2;
          width:rem(232px);
          height: auto;
          max-width:100%;
        }
      }
    }
  }
  &__bottom {
    padding: rem(80px 0 35px);
    overflow: hidden;
    @include media-breakpoint-down(xs) {
      padding: 3rem 0 1rem;
    }
  }
  &__marquee {
    display:inline-block;
    //padding:0 0 0 100%;
    animation: marquee 200s infinite linear;
    position: relative;
    z-index: 3;
    white-space: nowrap;
    span {
      display: inline-block;
      vertical-align: top;
      font-size: rem(200px);
      line-height: 1;
      text-transform: uppercase;
      text-shadow:rem(0 11px 106px cl(black, .53));
      color:cl(white, .8);
      font-weight: 700;
      @include media-breakpoint-down(xs) {
        font-size: rem(100px);
      }
      em {
        position: relative;
        font-style: normal;
        &:before {
          content: "";
          width:rem(100px);
          height: rem(150px);
          position: absolute;
          background: url(../img/svg/leaf-1.svg) no-repeat 50% 50%;
          background-size: contain;
          transform: rotate(70deg);
          bottom: 10%;
          left: 53%;
          @include media-breakpoint-down(xs)  {
            width:rem(50px);
            height: rem(100px);
          }
        }
      }
    }
  }
  &__decor {
    position: absolute;
    width: rem(200px);
    height: auto;
    transform: rotate(-70deg) scaleX(-1);
    bottom: -10%;
    left: 10%;
    z-index: 1;
    @include media-breakpoint-down(xs) {
      width: 8rem;
    }
  }
  &__inner {
    position: relative;
    z-index: 2;
    padding: 0 0 8%;
    @include media-breakpoint-up(xl) {
      min-height: 100vh;
    }
    @include media-breakpoint-up(lg) {
      background: url(../img/wave-top.png) no-repeat 50% 0;
      background-size: 100% auto;
    }
    &:before {
      content: "";
      @extend .full;
      background: url(../img/wave-bottom.png) no-repeat 50% 100%;
      background-size: 100% auto;
    }
  }
  .container {
    position: relative;
    z-index: 4;
  }
  &__text {
    font-size: rem(20px);
    line-height: 1.5;
    @include media-breakpoint-down(md) {
      background: cl(white);
      margin: 0 -15px;
      padding: 3rem 15px 1rem;
      &:before {
        content: "";
        position: absolute;
        left:0;
        top:98%;
        width:100%;
        height: 7rem;
        background: url(../img/wave-top-small.png) no-repeat 50% 0;
        background-size: 100% auto;
      }
    }

    @include media-breakpoint-down(xs) {
      padding: 3rem 15px;
    }
  }
  &__circle {
    color:cl(white);
    width:rem(697px);
    height:rem(697px);
    border-radius: 50%;
    text-align: center;
    box-shadow:rem(-30px 10px 0 cl(white, 0.74) , 0px 11px 27px cl(black, .44));
    align-items: center;
    position: relative;
    background-color: cl(primary);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    @include media-breakpoint-down(lg) {
      width:33rem;
      height: 33rem;
      margin: 0 auto;
    }
    @include media-breakpoint-down(xs) {
      width:rem(300px);
      height:rem(300px);
      box-shadow:rem(-20px 5px 0 cl(white, 0.74) , 0px 11px 27px cl(black, .44));
    }
  }
  &__leaf {
    position: absolute;
    width:rem(150px);
    height: auto;
    transform: rotate(-115deg);
    bottom: 10%;
    left: 0;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      top: 54%;
      right: 0;
      width: 4.75rem;
    }
  }
}