.events {
  &--page {
    padding: rem(20px 0 60px 0);
  }
  &__panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:1rem;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:1rem;
  }
  &__button {
    background: none;
    font-size: rem(16px);
    width:rem(35px);
    height: rem(35px);
    border-radius: 50%;
    border:2px solid cl(primary);
    color:cl(primary);
    &:hover,
    &:focus {
      background:cl(primary);
      color:cl(white);
    }
  }
  &__month {
    font-size: rem(18px);
  }
  &__calendar {
    font-size: rem(16px);
    width:rem(35px);
    height: rem(35px);
    border-radius: 50%;
    background: cl(primary);
    border:2px solid cl(primary);
    color:cl(white);
    &:hover,
    &:focus {
      border-color:cl(primary);
      color:cl(primary);
      background: cl(white);
    }
  }
  &__title {
    font-size: rem(40px);
    font-weight: normal;
    color:cl(primary);
    text-align: center;
  }
  &__view {
    font-weight: 600;
    font-size: rem(20px);
    letter-spacing: rem(.2px);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color:cl(black);
    }
  }
  &__item {
    display: flex;
    align-items: flex-start;
    position: relative;
    gap:rem(20px);
    background: cl(white);
    box-shadow: rem(0 35px 60px cl(black,.18));
    margin: rem(20px 0 0);
    padding: rem(20px 80px 25px 20px);
    border-radius: rem(20px);
    font-size: rem(16px);
    line-height: rem(24px);
    h2 {
      font-size: rem(24px);
      font-weight: 700;
      color:cl(secondary);
      margin: rem(0 0 5px);
      a {
        color:cl(secondary);
        &:hover {
          text-decoration: none;
          color:cl(primary);
        }
      }
    }
    p {margin: 0;}
  }
  &__pic {
    display: block;
    width:rem(100px);
    height: rem(100px);
    flex-shrink: 0;
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      transition: all .3s linear;
    }
  }
  &__image {
    display: block;
    overflow: hidden;
    height: rem(192px);
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    @include media-breakpoint-down(xs) {
      height: 15rem;
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      transition: all .3s linear;
    }
  }
  &__card {
    background: cl(white);
    box-shadow: rem(0 35px 60px cl(black,.18));
    border-radius: rem(20px);
    position: relative;
  }
  &__inner {
    padding: rem(25px 35px);
    font-size: rem(16px);
    line-height: rem(24px);
    color:currentColor;
    h3 {
      font-size: rem(20px);
      line-height: rem(30px);
      font-weight: 700;
      color:cl(primary);
      margin: rem(0 0 15px);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      height: rem(60px);
      a {
        color:cl(primary);
        &:hover {
          text-decoration: none;
          color:cl(secondary);
        }
      }
    }
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      margin: rem(0 0 35px);
      height: rem(72px);
    }
  }
  &__date {
    position: absolute;
    top:rem(-17px);
    right:rem(15px);
    background: cl(secondary);
    color:cl(white);
    text-align: center;
    height: rem(64px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(0 14px);
    border-radius: rem(0 10px);
    box-shadow: rem(0 7px 15px cl(black, .14));
    line-height: 1;
    z-index: 3;
    span {
      font-size: rem(14.5px);
      font-weight: 600;
      letter-spacing: rem(3px);
      text-transform: uppercase;
    }
    strong {
      font-size: rem(33px);
      font-weight: 700;
    }
  }
  &__add {
    position: absolute;
    bottom: rem(5px);
    right: rem(10px);
    font-size: rem(15px);
    font-weight: 500;
    color:cl(primary);
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    padding: rem(40px 0 0);
  }
}