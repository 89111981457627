.team {
  &__item {
    display: flex;
    gap:rem(40px);
    position: relative;
    &:not(:last-child) {
      padding: rem(0 0 40px);
    }
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
      gap:rem(30px);
    }
    @include media-breakpoint-up(lg) {
      &:before {
        content: "";
        position: absolute;
        left:rem(75px);
        height: 100%;
        width:1px;
        background: cl(orange);
      }
    }
    p {
      margin: rem(0 0 10px)!important;
    }
  }
  &__image {
    width:rem(150px);
    height: rem(150px);
    border-radius: 50%;
    padding: rem(5px);
    border:1px solid cl(orange);
    flex-shrink: 0;
    position: relative;
    z-index: 2;
    background: cl(white);
    img {
      display: block;
      width:100%;
      max-width:100%!important;
      height: 100%!important;
      object-fit: cover;
      margin: 0!important;
      border-radius: 50%;
    }
  }
  &__position {
    margin: rem(0 0 10px)!important;
    font-size: rem(16px);
  }
  &__mail {
    font-size: rem(15px);
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    a {
      color:cl(secondary);
    }
  }
  &__inner {
    @include media-breakpoint-up(lg) {
      padding: rem(10px 0 0);
    }
  }
}