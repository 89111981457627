.logo {
  &--main {
    width:rem(538px);
    flex-shrink: 0;
    transition: width .3s ease;
    @include media-breakpoint-down(lg) {
      width: 17rem!important;
    }
  }
  &--nav {
    display: none;
    width:22rem;
    height: auto;
    top:1rem;
    @extend .pos-centerX;
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
  &--footer {
    display: block;
    width:rem(255px);
    max-width:100%;
    margin: rem(0 0 36px);
    @include media-breakpoint-down(lg) {
      margin: 0 auto 2rem;
    }
  }
  &--cognia {
    display: inline-block;
    vertical-align: top;
    width:rem(110px);
    max-width:100%;
    @include media-breakpoint-down(xs) {
      width:rem(90px);
    }
  }
  &--somerset {
    display: inline-block;
    vertical-align: top;
    width:rem(150px);
    max-width:100%;
    @include media-breakpoint-down(lg) {
      width:rem(100px);
      display: block;
      margin: 0 auto 2rem;
    }
  }
  &--fortify {
    display: inline-block;
    vertical-align: top;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}