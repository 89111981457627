.btn {
  @extend .inline-b;
  background: cl(primary);
  color:cl(white);
  font-size: rem(16px);
  line-height: rem(48px);
  font-family: $font;
  font-weight: 700;
  border: rem(1px solid cl(primary));
  border-radius: rem(25px);
  padding: rem(0 30px);
  outline: 0;
  white-space: nowrap;
  transition: all .3s ease;
  &:hover,
  &:focus  {
    border-color:cl(primary);
    background: cl(primary, 0.9);
    color:cl(white);
    text-decoration: none;
  }
  &--primary-outline {
    background: none;
    color:cl(primary);
    border-color:cl(primary);
    &:hover,
    &:focus  {
      background: cl(primary, 0.1);
      color:cl(primary);
      border-color:cl(primary);
    }
  }
  &--secondary {
    background: cl(secondary);
    border-color:cl(secondary);
    color:cl(white);
    &:hover,
    &:focus  {
      background: cl(secondary, 0.9);
      border-color:cl(secondary);
      color:cl(white);
    }
  }
  &--secondary-outline {
    background: none;
    color:cl(secondary);
    border-color:cl(secondary);
    &:hover,
    &:focus  {
      background: cl(secondary, 0.1);
      color:cl(secondary);
      border-color:cl(secondary);
    }
  }
  &--white {
    background: cl(white);
    border-color:cl(white);
    color:cl(secondary);
    &:hover,
    &:focus  {
      background: cl(white, 0.9);
      border-color:cl(white);
      color:cl(secondary);
    }
  }
  &--white-outline {
    background: none;
    color:cl(white);
    border-color:cl(white);
    &:hover,
    &:focus  {
      background: cl(white, 0.1);
      color:cl(white);
      border-color:cl(white);
    }
  }
  &--red {
    background: cl(red);
    border-color:cl(red);
    color:cl(white);
    &:hover,
    &:focus  {
      background: cl(red, 0.9);
      border-color:cl(red);
      color:cl(white);
    }
  }
  &--red-outline {
    background: none;
    color:cl(red);
    border-color:cl(red);
    &:hover,
    &:focus  {
      background: cl(red, 0.1);
      color:cl(red);
      border-color:cl(red);
    }
  }
  &--orange {
    background: cl(orange);
    border-color:cl(orange);
    color:cl(white);
    &:hover,
    &:focus  {
      background: cl(orange, 0.9);
      border-color:cl(orange);
      color:cl(white);
    }
  }
  &--shadow {
    box-shadow: rem(0 14px 32px cl(black, .21));
  }
}