.students {
  position: relative;
  margin: rem(-200px 0);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
  }
  @include media-breakpoint-down(lg) {
    margin: 0;
  }
  @include media-breakpoint-down(xs) {
    padding: 2rem 0;
  }
  .container {
    position: relative;
    z-index: 3;
  }
  &__slogan {
    font-size: rem(30px);
    line-height: 1.5;
    color:cl(primary);
  }
  &__text {
    @include media-breakpoint-down(lg) {
        margin-bottom: 4rem;
    }
  }
  &__side {
    position: relative;
    @include media-breakpoint-down(sm) {
      // transform: scale(0.7);
    }
    @include media-breakpoint-down(xs) {
      // transform: scale(1);
    }
  }
  &__decor {
    position: absolute;
    width: rem(200px);
    height: auto;
    transform: rotate(-70deg) scaleX(-1);
    right: 73%;
    bottom: 20%;
    // filter: drop-shadow(2px 4px 0px white);
    @include media-breakpoint-down(xs) {
      width: rem(100px);
      right:1rem;
      bottom: -5rem;
    }
  }
  &__list {
    list-style: none;
    width: rem(724px);
    display: flex;
    flex-wrap: wrap;
    gap: rem(14px 11px);
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      margin: 0 auto;
    }
    @include media-breakpoint-down(xs) {
      width:auto;
      gap:1rem;
    }
    li {
      z-index: 1;
      width:rem(172px);
      height:rem(172px);
      @include media-breakpoint-down(xs) {
        width:calc((100% - 2rem)/3);
        height: 8rem;
      }
      &:nth-child(2) {
        @include media-breakpoint-up(sm) {
          margin-right: rem(369px);
        }
      }
      &:nth-child(5) {
        @include media-breakpoint-up(sm) {
          margin-right: rem(186px);
        }
      }
      &:nth-child(6) {
        @include media-breakpoint-up(sm) {
          margin-left: rem(186px);
        }
      }
      &:nth-child(9) {
        @include media-breakpoint-up(sm) {
          margin-left: rem(369px);
        }
      }
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width:100%;
      padding: rem(30px);
      position: relative;
      transition: transform .3s ease;
      box-shadow:rem(0 11px 35px cl(black, .16));
      background: cl(white);
      border-radius: rem(15px);
      &:hover {
        transform: scale(1.1);
      }
      img {
        position: relative;
        z-index: 2;
        max-width: 100%;
        height: auto;
      }
    }
  }
  &__image {
    position: absolute;
    right:0;
    bottom: rem(-160px);
    width:rem(1109px);
    height: rem(977px);
    -webkit-mask: url(../img/image-mask.png);
    -webkit-mask-size: 100%;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
    z-index: 2;
    @include media-breakpoint-down(md) {
      width: 90%;
    }
    @include media-breakpoint-down(xs)  {
      height: 23.0625rem;
      bottom: 56rem;
    }
  }
  &__pic {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width:100%;
      background: rgba(75, 144, 206, .22);
    }
  }
  &__leaf {
    position: absolute;
    width: rem(150px);
    height: auto;
    transform: rotate(70deg);
    left: 5%;
    top: -8%;
    z-index: 101;
    @include media-breakpoint-down(xs) {
      width: rem(100px);
      left: 2rem;
      top: -6rem;
    }
  }
}