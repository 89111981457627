.section {
    position: relative;
    @include gradient-y(#22337b, #276750);
    overflow: hidden;
    padding: 0 0 17%;
    background-attachment: fixed;
    @include media-breakpoint-up(xl) {
    //   background-attachment: fixed;
    }
    &__decor {
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        &:before {
            content: "";
            position: absolute;
            left:0;
            width:100%;
            background: cl(white);
        }
        &--top{
            &:before {
                top:0;
                height: rem(277px);
                @include media-breakpoint-down(lg)  {
                    // height: 60.5rem;
                }
                @include media-breakpoint-down(xs)  {
                    // height: 90.5rem;
                }
            }
            &:after {
                content: "";
                @extend .full;
                z-index: 2;
                background: url(../img/bottom-mask-1x.png) no-repeat 50% 17.1875rem;
                background-size: 100% auto;
                @media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
                    background-image:url(../img/bottom-mask-2x.png);
                }
                @include media-breakpoint-down(xs) {
                    background-image:url(../img/bottom-mask-mobile-2x.png);
                }
                @include media-breakpoint-down(lg) {
                    // background-position: 50% 60rem;
                }
                @include media-breakpoint-down(lg) {
                    // background-position: 50% 90rem;
                }
            }
        }
        &--bottom{
            &:before {
                bottom:0;
                height: 2.1rem;
            }
            &:after {
                content: "";
                @extend .full;
                z-index: 2;
                background: url(../img/banner-mask-1x.png) no-repeat right 50% bottom 2rem;
                background-size: 100% auto;
                @media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
                    background-image:url(../img/banner-mask-2x.png);
                }
                @include media-breakpoint-down(xs) {
                    background-image:url(../img/banner-mask-mobile-2x.png);
                }
                @include media-breakpoint-down(lg) {
                    // background-position: 50% 60rem;
                }
                @include media-breakpoint-down(lg) {
                    // background-position: 50% 90rem;
                }
            }
        }
    }
}