.info {
  &--section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    z-index: 3;
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    &:before {
      content: "";
      @extend .full;
      background: cl(primary, .42);
    }
    &:after {
      content: "";
      position: absolute;
      bottom:-2px;
      left:0;
      width:100%;
      height: 4px;
      background: cl(white);
    }
  }
  &__top {
    background: cl(white);
    padding: rem(0 0 20px);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(sm) {
      padding-top: 1rem;
    }
    @include media-breakpoint-down(xs) {
      padding-top: 3rem;
    }
  }
  &__inner {
    background: url(../img/wave-top-reverse.png) no-repeat 50% 0;
    background-size: 100% auto;
    position: relative;
    z-index: 2;
    padding: 0 0 8%;
    @include media-breakpoint-up(xl) {
      min-height: 100vh;
    }
    &:before {
      content: "";
      @extend .full;
      background: url(../img/wave-bottom-reverse.png) no-repeat 50% 100%;
      background-size: 100% auto;
    }
  }
  .container {
    position: relative;
    z-index: 4;
  }
  &__list {
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    --gap-side: 3.750rem;
    gap:2rem var(--gap-side);
  }
  &__item {
    border-radius: rem(30px);
    overflow: hidden;
    box-shadow:rem(-5px 30px 60px cl(black, .34));
    position: relative;
    --cols: 3;
    width: calc((100% - var(--gap-side) * (var(--cols) - 1)) / var(--cols));
    @include media-breakpoint-down(md) {
      --cols: 2;
    }
    @include media-breakpoint-down(xs) {
      --cols: 1;
      max-width:rem(400px);
    }
    &:hover {
      .info__text {
        top:0;
        color:cl(white);
        &:before {
          border-radius: 0;
          height: 100%;
          background: cl(primary, .9);
        }
      }
    }
  }
  &__image {
    height: rem(450px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  &__hold {
    position: relative;
    z-index: 3;
  }
  &__text {
    position: absolute;
    left:0;
    top:rem(345px);
    width:100%;
    height: 100%;
    color:cl(primary);
    padding: rem(40px 25px 25px);
    font-size: rem(16px);
    line-height: 1.6;
    transition: top .3s ease, color .3s ease;
    &:before {
      content: "";
      width:rem(446px);
      height: rem(146px);
      position: absolute;
      top:0;
      left: 50%;
      transform: translateX(-50%);
      background: cl(white, .86);
      border-radius: 50%;
      transition: height .3s ease, border-radius .3s ease, background .3s ease;
    }
    h3 {
      font-size: rem(34px);
      line-height: 1;
      margin: rem(0 0 25px);
      font-weight: normal;
      text-align: center;
    }
    .text-scroll {
      height: rem(320px);
    }
    p {
      margin: rem(0 0 16px);
    }
    ul {
      margin: rem(0 0 16px);
      list-style: none;
      li {
        padding: rem(0 0 0 20px);
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left:0;
          top:rem(9px);
          width:rem(4px);
          height: rem(4px);
          border-radius: 50%;
          background: cl(white);
        }
      }
    }
  }
  &__links {
    list-style: none;
    margin: rem(190px 0 -80px);
    position: relative;
    @include media-breakpoint-down(xs) {
      margin: rem(50px 0 20px);
    }
    li {
      margin: rem(0 0 36px);
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: cl(white);
        border-radius: rem(30px);
        padding: rem(0 30px);
        height: rem(100px);
        width:rem(360px);
        box-shadow:rem(-5px 30px 60px cl(black, .34));
        font-size: rem(40px);
        line-height: 1;
        color:cl(primary);
        font-weight: normal;
        text-transform: uppercase;
        @include media-breakpoint-down(xs) {
          width:100%;
        }
        i {
          font-size: rem(30px);
          color:cl(secondary);
        }
        &:hover,
        &:focus {
          text-decoration: none;
          background: cl(primary);
          color:cl(white);
          i {
            color:cl(white);
          }
        }
      }
    }
  }
  &__leaf {
    position: absolute;
    width:rem(150px);
    height: auto;
    transform: rotate(75deg);
    top: 58%;
    right: 15%;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      top: auto;
      bottom: 0;
      right: 5%;
      width:rem(100px);
    }
    @include media-breakpoint-down(xs) {
      top: auto;
      bottom: -2rem;
      right: 10%;
      transform: rotate(-75deg) scaleX(-1);
      width:rem(100px);
    }
  }
}