.information {
    padding: rem(200px 0 300px);
    @include gradient-y(#22337b, #276750);
    overflow: hidden;
    background-attachment: fixed;
    position: relative;
    @include media-breakpoint-down(xs) {
        padding: 7rem 0;
    }
    .container {
        position: relative;
        z-index: 5;
    }
    &__text {
        font-size: rem(18px);
        line-height: 1.5;
        color:cl(white);
        ul {
            li {
                list-style: none;
                padding: rem(0 0 0 25px);
                margin: rem(0 0 15px);
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    left:0;
                    top:rem(9px);
                    width:rem(9px);
                    height: rem(9px);
                    border-radius: 50%;
                    background-color: cl(orange);
                }
            }
        }
    }
    &__side {
        position: relative;
        @include media-breakpoint-down(md) {
            margin-bottom: 3rem;
        }
    }
    &__image {
        display: block;
        width:100%;
        height: auto;
        box-shadow:rem(0 3px 51px cl(black, .34));
        border-radius: rem(30px);
        position: relative;
        z-index: 2;
    }
    &__leaf {
      position: absolute;
      width: rem(200px);
      height: auto;
      transform: rotate(-70deg) scaleX(-1);
      right: 80%;
      bottom: -35%;
      @include media-breakpoint-down(xs) {
        width: rem(100px);
        bottom: -26%;
      }
    }
    &__decor {
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        &:before {
            content: "";
            position: absolute;
            left:0;
            width:100%;
            height: 2.1rem;
            background: cl(white);
        }
        &--top{
            &:before {
                top:0;
                @include media-breakpoint-down(lg)  {
                    // height: 60.5rem;
                }
                @include media-breakpoint-down(xs)  {
                    // height: 90.5rem;
                }
            }
            &:after {
                content: "";
                @extend .full;
                z-index: 2;
                background: url(../img/bottom-mask-1x.png) no-repeat 50% 2rem;
                background-size: 100% auto;
                @media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
                    background-image:url(../img/bottom-mask-2x.png);
                }
                @include media-breakpoint-down(xs) {
                    background-image:url(../img/bottom-mask-mobile-2x.png);
                }
                @include media-breakpoint-down(lg) {
                    // background-position: 50% 60rem;
                }
                @include media-breakpoint-down(lg) {
                    // background-position: 50% 90rem;
                }
            }
        }
        &--bottom{
            &:before {
                bottom:0;
            }
            &:after {
                content: "";
                @extend .full;
                z-index: 2;
                background: url(../img/banner-mask-1x.png) no-repeat right 50% bottom 2rem;
                background-size: 100% auto;
                @media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
                    background-image:url(../img/banner-mask-2x.png);
                }
                @include media-breakpoint-down(xs) {
                    background-image:url(../img/banner-mask-mobile-2x.png);
                }
                @include media-breakpoint-down(lg) {
                    // background-position: 50% 60rem;
                }
                @include media-breakpoint-down(lg) {
                    // background-position: 50% 90rem;
                }
            }
        }
    }
}