.bottom {
    position: relative;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: rem(200px 0 0);
    box-shadow:rem(0 15px 15px cl(black, .14));
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    @include media-breakpoint-down(md)  {
        padding: 10rem 0 0;
    }
    &:before {
      content: "";
      @extend .full;
      background: radial-gradient(ellipse at center,  rgba(51,69,141,0.4) 0%,rgba(51,69,141,1) 80%); 
      z-index: 2;
    }
    &:after {
        content: "";
        position: absolute;
        left:0;
        top: -1px;
        width:100%;
        height: 100%;
        z-index: 2;
        background: url(../img/bottom-mask-1x.png) no-repeat 50% 0;
        background-size: 100% auto;
        z-index: 3;
        @media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
          background-image:url(../img/bottom-mask-2x.png);
        }
        @include media-breakpoint-down(xs) {
          background-image:url(../img/bottom-mask-mobile-2x.png);
        }
      }
      &__text {
        padding: rem(0 0 50px);
        position: relative;
        text-align: center;
        z-index: 4;
        color:cl(white);
        font-size: rem(20px);
        line-height: 1.5;
        @include media-breakpoint-down(md)  {
            padding: 0 0 2rem;
        }
        @include media-breakpoint-down(xs)  {
            // padding: 6rem 0 3rem;
        }
      }
      &__shadow {
        position: absolute;
        left:0;
        bottom: 0;
        width:100%;
        height: 100%;
        z-index: 2;
        background: linear-gradient(to top,  rgba(61,127,188,1) 10%,rgba(61,127,188,0) 100%);
      }
      &__leaf {
        position: absolute;
        width: rem(200px);
        height: auto;
        transform: rotate(-70deg) scaleX(-1);
        top: 0;
        right: 10%;
        z-index: 4;
        filter: drop-shadow(2px 4px 0px white);
        @include media-breakpoint-down(md)  {
            top: -10%;
        }
        @include media-breakpoint-down(xs) {
            width: 8rem;
            top: -2rem;
        }
    }
}