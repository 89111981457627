.faq{
  position: relative;
  .card {
    background: cl(white);
    position: relative;
    margin: rem(0 0 13px);
    font-family: $font;
    border: 0;
    border-radius: rem(8px);
    &.shadow{
      box-shadow: 0px 9px 40px 0px rgba(0, 0, 0, 0.13);
    }
    .card-header {
      background: none;
      padding: 0;
      border-bottom: 0;
      .head-link {
        border: 1px solid cl(primary);
        border-bottom: 0;
        border-bottom-right-radius: rem(0px);
        border-bottom-left-radius: rem(0px);
        &.collapsed {
          border: 1px solid #d1d6d9;
          border-radius: rem(8px);
        }
      }
      button {
        font-size: rem(18px);
        line-height: rem(30px);
        margin: rem(0 0 0px);
        font-family: $font;
        font-weight: 700;
        background: cl(white);
        width:100%;
        display: block;
        border:0;
        color:cl(primary);
        padding: rem(20px 60px 20px 60px);
        position: relative;
        text-align: left;
        letter-spacing: rem(0.2px);
        border-radius: rem(8px);
        @include media-breakpoint-down(sm) {
          font-size: rem(20px);
          line-height: rem(26px);
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(18px);
          line-height: rem(26px);
          padding: rem(14px 60px 14px 60px);
        }
        &:focus {
          outline: none;
        }

        &.collapsed {
          .plus-minus{
            &:after{
              opacity: 1;
            }
          }
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(20px);
          line-height: rem(30px);
        }
        .plus-minus{
          position: absolute;
          right:rem(30px);
          top: 50%;
          transform: translateY(-50%) rotate(-180deg);
          transition: all .3s ease;
          width: rem(24px);
          height: rem(24px);
          @include media-breakpoint-down(xs) {
            width: rem(20px);
            height: rem(20px);
            right:rem(20px);
          }
          &:before{
            content: "";
            position: absolute;
            width: 100%;
            height: rem(2px);
            @extend .pos-centerY;
            left: 0;
            background-color: cl(primary);
          }
          &:after{
            content: "";
            position: absolute;
            height: 100%;
            width: rem(2px);
            @extend .pos-centerX;
            background-color: cl(primary);
            top: 0;
            opacity: 0;
            transition: 0.3s ease-in-out;
          }
        }
      }
      .logo{
        position: absolute;
        left:rem(20px);
        @extend .pos-centerY;
        transition: all .3s ease;
        z-index: 2;
        width:rem(20px);
        height: rem(20px);
        object-fit: contain;
        border-radius: 50%;

      }
    }
    .collapse-body {
      border: 1px solid cl(primary);
      border-bottom-right-radius: rem(8px);
      border-bottom-left-radius: rem(8px);
      border-top: 0;

    }
    .card-body {
      background-color: cl(white);
      padding: rem(5px 100px 20px 60px);
      font-size: rem(18px);
      line-height: rem(28px);
      border:0;
      border-radius: rem(8px);
      font-family: $font;
      color: cl(text);
      @include media-breakpoint-down(xs) {
        padding: rem(10px 26px 20px 20px);
      }
      p {
        margin: 0 0 1.5rem;
        font-size: rem(18px);
        line-height: rem(28px);

      }
      &:focus {
        outline: none;
      }
      div{
        &:focus {
          outline: none;
        }
      }
      ul,ol{
        padding-left: 2rem;
      }

    }
  }
}