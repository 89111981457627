.congrats {
    padding: rem(50px 0 10px);
    position: relative;
    .container {
        position: relative;
        z-index: 4;
    }
    &__block {
        display: flex;
        align-items: center;
        justify-content: center;
        gap:rem(45px);
        margin: rem(0 0 20px);
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
        h2 {
            font-size: rem(36px);
            text-transform: uppercase;
            line-height: 1.3;
            color:cl(orange);
            // margin: rem(68px 0);
            font-weight: 700;
            position: relative;
            @include media-breakpoint-down(md) {
                order:1;
            }
            &:before {
                content: "";
                position: absolute;
                left:0;
                bottom: 100%;
                width:rem(230px);
                height: rem(74px);
                background: url(../img/svg/stars-top.svg) no-repeat;
                background-size: contain;
            }
            &:after{
                content: "";
                position: absolute;
                left:0;
                top: 100%;
                width:rem(245px);
                height: rem(245px);
                background: url(../img/svg/stars-bottom.svg) no-repeat;
                background-size: contain;
            }
        }
    }
    &__item {
        width:rem(318px);
        position: relative;
        &:first-child {
            @include media-breakpoint-down(md) {
                order:2;
            }
        }
        &:last-child {
            @include media-breakpoint-down(md) {
                order:3;
            }
        }
    }
    &__leaf {
        position: absolute;
        width: rem(200px);
        height: auto;
        transform: rotate(-70deg) scaleX(-1);
        bottom: 8%;
        right: 95%;
        z-index: 1;
        @include media-breakpoint-down(xs) {
          width: 8rem;
        }
    }
    &__top {
        padding: rem(22px);
        border-radius: rem(20px);
        box-shadow:rem(0 14px 32px cl(black, .21));
        margin-bottom: rem(45px);
        height: rem(369px);
        position: relative;
        z-index: 2;
        background: cl(white);
    }
    &__image {
        height: rem(280px);
        margin-bottom: rem(13px);
        img {
            display: block;
            width:100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__name {
        background: cl(orange);
        color:cl(white);
        text-align: center;
        height: rem(76px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: rem(0 auto -22px);
        position: relative;
        width:calc(100% - 2rem);
        strong {
            font-size: rem(20px);
            font-weight: 700;
            margin-bottom: rem(5px);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical; 
        }
        span {
            font-size: rem(13px);
            text-transform: uppercase;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical; 
        }
        .left,
        .right {
            position: absolute;
            top:0;
            height: 100%;
            width:2px;
            &:before,
            &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
            }
            &:before {
                top:0;
                border-top: rem(38px solid cl(orange));
            }
            &:after {
                bottom: 0;
                border-bottom: rem(38px solid cl(orange));
            }
        }
        .left {
            right:calc(100% - 1px);
            &:before,
            &:after {
                right:0;
                border-left: 1rem solid transparent;
            }
        }
        .right {
            left:calc(100% - 1px);
            &:before,
            &:after {
                left:0;
                border-right: 1rem solid transparent;
            }
        }
        &--mint {
            background: cl(mint);
            .left,
            .right {
                &:before {
                    border-top-color: cl(mint);
                }
                &:after {
                    border-bottom-color: cl(mint);
                }
            }
        }
    }
    h3 {
        text-align: center;
        color:cl(white);
        font-size: rem(20px);
        font-weight: 700;
        margin: 0;
    }
    &__bottom {
        overflow: hidden;
      }
      &__marquee {
        display:inline-block;
        //padding:0 0 0 100%;
        animation: marquee 200s infinite linear;
        position: relative;
        z-index: 3;
        white-space: nowrap;
        span {
          display: inline-block;
          vertical-align: top;
          font-size: rem(200px);
          line-height: 1;
          text-transform: uppercase;
          color:cl(white, .8);
          font-weight: 700;
          -webkit-text-stroke: 1px cl(primary);
          -webkit-text-fill-color: #fff;
          @include media-breakpoint-down(xs) {
            font-size: rem(100px);
          }
          em {
            position: relative;
            font-style: normal;
            &:before {
              content: "";
              width:rem(100px);
              height: rem(150px);
              position: absolute;
              background: url(../img/svg/leaf-1.svg) no-repeat 50% 50%;
              background-size: contain;
              transform: rotate(70deg);
              bottom: 10%;
              left: 53%;
              @include media-breakpoint-down(xs)  {
                width:rem(50px);
                height: rem(100px);
              }
            }
          }
        }
      }
    }